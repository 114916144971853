import manifoldCSSReset from '@manifoldxyz/css-reset';
import './scss/walletConnect.css';
import { getAllChildren } from '@/common/functions';
import MConnect from '@/exports/MConnect.vue';
import { renderComponentWithApp } from '../build/mount';

// track app-component Vue instances for proper destroying later
const renderedComponents = new Map();
let widgetUpdateObserver: MutationObserver;
manifoldCSSReset();

const replaceWidget = (el: HTMLElement) => {
  // grab the DOM element's data- properties to use as propsData
  const data = el.dataset;

  // be sure to give proper fallback values for each prop
  const propsData = {
    fallbackProvider: 'fallbackProvider' in data ? data.fallbackProvider : undefined,
    network: data.network ? parseInt(data.network) : undefined,
    parentFrameUrl: 'parentFrameUrl' in data ? data.parentFrameUrl : undefined,
    avatar: 'avatar' in data ? data.avatar : undefined,
    multi: data.multi ? JSON.parse(data.multi) : false,
    minimal: data.minimal ? JSON.parse(data.minimal) : false,
    autoReconnect: data.autoReconnect ? JSON.parse(data.autoReconnect) : true,
    strictAuth: data.strictAuth ? JSON.parse(data.strictAuth) : true,
    delayAuth: data.delayAuth ? JSON.parse(data.delayAuth) : false,
    message: 'message' in data ? data.message : undefined,
    showChain: 'showChain' in data,
    showBalance: 'showBalance' in data,
    clientId: 'clientId' in data ? data.clientId : undefined,
    appName: 'appName' in data ? data.appName : undefined,
    detectApp: data.detectApp ? JSON.parse(data.detectApp) : false,
    grantType: 'grantType' in data ? data.grantType : undefined,
    redirectUri: 'redirectUri' in data ? data.redirectUri : undefined,
    overrideConnectText: 'overrideConnectText' in data ? data.overrideConnectText : undefined,
    connectWalletImage: 'connectWalletImage' in data ? data.connectWalletImage : undefined
  };

  const renderedComponent = renderComponentWithApp({
    el: el,
    component: MConnect,
    props: propsData
  });

  const config = {
    attributes: true,
    childList: false,
    subtree: false
  };

  widgetUpdateObserver.observe(el, config);

  renderedComponents.set(el, renderedComponent);
};

const destroyWidget = (el: HTMLElement) => {
  const renderedComponentRef = renderedComponents.get(el);
  if (renderedComponentRef) {
    // unmount and destroy the pre-existing Vue app-component
    renderedComponentRef();
    renderedComponents.delete(el);
  }
};

const updateWidgetMutationCallback = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName !== 'data-v-app') {
      // destroy pre-existing app-component before replacing it with new one
      destroyWidget(mutation.target as HTMLElement);
      replaceWidget(mutation.target as HTMLElement);
    }
  });
};

const replaceDynamiclyAddedDivs = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === 'm-connect') {
        replaceWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.forEach((child) => {
        if ((child as HTMLElement)?.dataset?.widget === 'm-connect') {
          replaceWidget(child as HTMLElement);
        }
      });
    });

    mutation.removedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === 'm-connect') {
        destroyWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.forEach((child) => {
        if ((child as HTMLElement)?.dataset?.widget === 'm-connect') {
          destroyWidget(child as HTMLElement);
        }
      });
    });
  });
};

if (window) {
  window.addEventListener('load', () => {
    // for reuse
    widgetUpdateObserver = new window.MutationObserver(updateWidgetMutationCallback);

    // manually sweep the DOM for pre-existing widget divs that need rendering
    const elements = document.querySelectorAll("[data-widget='m-connect']");
    elements.forEach((el: Element) => {
      replaceWidget(el as HTMLElement);
    });

    // listen for new widget divs being added to the document
    const bodyNode = document.querySelector('body');
    if (bodyNode) {
      const config = {
        childList: true,
        subtree: true
      };
      const observer = new window.MutationObserver(replaceDynamiclyAddedDivs);
      observer.observe(bodyNode, config);
    }
  });
}
