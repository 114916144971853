export const getAllChildren = (htmlElement: Element): Element[] => {
  if (!htmlElement.children || htmlElement.children?.length === 0) {
    return [htmlElement];
  }

  const allChildElements = [];

  for (let i = 0; i < htmlElement.children.length; i++) {
    const children = getAllChildren(htmlElement.children[i]);
    if (children) allChildElements.push(...children);
  }
  allChildElements.push(htmlElement);

  return allChildElements;
};
