import { inject, provide } from 'vue';
import { mixins, Options, Vue } from 'vue-class-component';
import { EthereumNetwork, OAuthGrantType } from '@manifoldxyz/manifold-sdk';

/*
 * Adding a new prop? You'll want to declare in two places:
 *   1. MConnectProps
 *   2. @Options -> props of MConnectProvidePropsMixin
 *
 * Optionally, you can enforce any new prop-dependency rules
 * in the created() hook of MConnectProvidePropsMixin.
 */

// Defined here once so that the inject() and provide() calls made by
// MConnectInjectPropsMixin and MConnectProvidePropsMixin define identical variables.
// Reference: https://vuejs.org/guide/components/provide-inject.html
class MConnectProps extends Vue {
  showBalance = false;
  connectWalletImage = '';
  showChain = false;
  avatar = '';
  multi = false;
  minimal = false;
  fallbackProvider = '';
  network: EthereumNetwork = EthereumNetwork.MAINNET;
  parentFrameUrl = '';
  autoReconnect = true;
  overrideConnectText = '';
  strictAuth?: boolean;
  delayAuth = false;
  clientId = '';
  appName = '';
  grantType = OAuthGrantType.SIGNATURE;
  detectApp = false;
  message = '';
}

// Used by WalletMixin to inject() back in all parent-provided props as variables
// Reference: https://vuejs.org/guide/components/provide-inject.html
export class MConnectInjectPropsMixin extends mixins(MConnectProps) {
  created(): void {
    // get all property definitions declared in MConnectProvidePropsMixin
    const keys = Object.keys(new MConnectProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      (this as any)[key] = inject(key); // eslint-disable-line
    }
  }
}

// Used by MConnect to provide() all its props to children components
// Reference: https://vuejs.org/guide/components/provide-inject.html
@Options({
  props: {
    showBalance: {
      default: true,
      type: Boolean
    },
    connectWalletImage: {
      default: '',
      type: String
    },
    showChain: {
      default: true,
      type: Boolean
    },
    avatar: {
      default: '',
      type: String
    },
    multi: {
      default: false,
      type: Boolean
    },
    minimal: {
      default: false,
      type: Boolean
    },
    fallbackProvider: {
      type: String
    },
    network: {
      type: Number
    },
    parentFrameUrl: {
      type: String
    },
    autoReconnect: {
      default: true,
      type: Boolean
    },
    overrideConnectText: {
      default: '',
      type: String
    },
    strictAuth: {
      type: Boolean,
      default: true
    },
    delayAuth: {
      type: Boolean,
      default: false
    },
    clientId: {
      type: String,
      default: ''
    },
    appName: {
      type: String,
      default: ''
    },
    grantType: {
      type: String,
      default: OAuthGrantType.SIGNATURE
    },
    detectApp: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  }
})
export class MConnectProvidePropsMixin extends mixins(MConnectProps) {
  /*
   * Use this created hook to enforce any prop-dependency rules.
   */
  created(): void {
    if (this.multi && this.minimal) {
      throw Error('Props multi and minimal are mutually exclusive');
    }

    if ((this.appName || this.clientId) && !(this.appName && this.clientId)) {
      throw Error('Props app-name and client-id must be provided together');
    }

    if (this.detectApp && (this.appName || this.clientId)) {
      throw Error('Props app-name and client-id cannot be provided if detect-app is true');
    }

    if (this.fallbackProvider && !this.network) {
      throw Error('Prop network must be provided if fallbackProvider is provided');
    }

    // define all properties for children who use the MConnectInjectPropsMixin
    const keys = Object.keys(new MConnectProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      provide(key, (this as any)[key]); // eslint-disable-line
    }
  }
}
