import { Web3Provider } from '@ethersproject/providers';

// events
export const AUTHENTICATED = 'm-authenticated';
export const UNAUTHENTICATED = 'm-unauthenticated';
export const REAUTHENTICATE = 'm-reauthenticate';
export const MANIFOLD_LOAD_REQUEST = 'm-refresh-widgets';

export const ETHEREUM_NETWORK_COLORS: Record<number, string> = {
  1: '#59B3AE',
  3: '#EC5A8C',
  4: '#EFC45B',
  5: '#4D99EB',
  42: '#886BF6',
  137: '#7B4ADD'
};

export const ETHEREUM_NETWORK_NAMES: Record<number, string> = {
  1: 'MAINNET',
  3: 'ROPSTEN',
  4: 'RINKEBY',
  5: 'GOERLI',
  42: 'KOVAN',
  137: 'MATIC'
};

export interface AbstractProvider extends Web3Provider {
  provider: {
    isCoinbaseWallet: boolean;
    isBraveWallet: boolean;
    isLedgerConnect: boolean;
    isMetaMask: boolean;
    disconnect: () => void;
  };
}

export const WIDGET = 'connect';

interface ManifoldRefreshDetail {
  widget: string; // the name of the widget script
}

export type MManifoldRefreshWidgetsEvent = CustomEvent<ManifoldRefreshDetail>;
